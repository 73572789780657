  import React, {useState, useEffect} from "react";

const Timer = ({isActive, setLooseScreen}) =>{

    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [dixSeconds, setDixSeconds] = useState(0)
    const [dixMinutes, setDixMinutes] = useState(0)
    

    useEffect(() => {
        let interval = null;
        if (isActive) {
          interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
          }, 1000);
        } else if (!isActive && seconds !== 0) {
          clearInterval(interval);
        }
        return () => clearInterval(interval);
      }, [isActive, seconds]);
   

    if(seconds == 10){
        setSeconds(0)
        setDixSeconds(dixSeconds => dixSeconds +1)
      }
  
      if( dixSeconds == 6 ){
          setMinutes(minutes => minutes +1)
          setDixSeconds(0)
      }
      if(minutes == 9){
        setDixMinutes(dixMinutes => dixMinutes +1)
        setMinutes(0)
      }
  
      if(dixMinutes == 3){
        setLooseScreen(true)
      }

    return(   
        <div className="timer" > 
            <div className="timerContent" >{dixMinutes} {minutes} : {dixSeconds} {seconds} </div>
        </div>
    )
}

export default Timer