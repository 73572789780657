import React, { useRef, useEffect, useState } from "react";
import redCross from '../../assets/redCross.png'

const ModalCodeChambre = ({ setCodeModal, openValise, setEnigmeFind, enigmeFind}) => {

    const inputContent = useRef()
    const inputContent1 = useRef()
    const inputContent2 = useRef()
    const wrongCode = useRef()  
    
    function handleChange(e){
      let codeTest = inputContent.current.value +  inputContent1.current.value +  inputContent2.current.value
      if (codeTest.length === 3){
          if( codeTest == '315' ){
              setCodeModal(false)
              openValise()
              setEnigmeFind( enigmeFind => [...enigmeFind, 'valise'] )
          } 
          else{
              wrongCode.current.classList.remove('unDisplayed')
          }
        }

        // console.log(e.target.name)
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
      
        // Check if they hit the max character length
        if (value.length >= maxLength) {
          // Check if it's not the last input field
          if (parseInt(fieldIndex, 10) < 3) {
            // Get the next input field
            const nextSibling = document.querySelector(
              `input[name=n-${parseInt(fieldIndex, 10) + 1}]`
            );
      
            // If found, focus the next field
            if (nextSibling !== null) {
              nextSibling.focus();
            }
          }
        }
    }

    function closeModal(){
        setCodeModal(false)
    }
    return (

        <div className='modalCodeChambreContainer'>

            <img src={redCross} alt="" className="redCross" width={20} onClick={closeModal}/>

            <p>Vous devez entrer le bon code pour ouvrir la valise</p>

            <div className="inputContainer" >
                <input type="text" autoFocus  maxLength='1' name='n-1'  ref={inputContent} onChange={handleChange}  />
                <input type="text"  maxLength='1' ref={inputContent1} name='n-2' onChange={handleChange}   />
                <input type="text" maxLength='1'  ref={inputContent2} name='n-3' onChange={handleChange}   />
            </div>

            <span>Dans le bureau, Victor Hugo pourrait m'aider.</span>
            <div className="wrongCode unDisplayed" ref={wrongCode} >ce code n'est pas correct</div>

        </div>


    )
}


export default ModalCodeChambre