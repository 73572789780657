import React, { useState, useRef } from "react";
import arrow from '../../assets/backArrow.png'
import teleFreeImg from '../../assets/room/salon/teleFree.png'
import carnetImg from '../../assets/room/salon/carnetSalon.png'
import tiroirLeftImg from '../../assets/room/salon/tiroirLeft.png'
import tiroirRightImg from '../../assets/room/salon/tiroirRight.png'
import freeBoxImg from '../../assets/room/salon/freeBox.png'
import manetteImg from '../../assets/room/salon/manette.png'
import redCross from '../../assets/redCross.png'
import './salon.css'
import PopUpAddiction from "../../popUpAddiction/popUpAddiction";
import pcPortableJeux from '../../assets/room/salon/addictionJeux.png'
import PopUpImg from "../../popUpImg/popUpImg";
import returnText from "../../function/textAddiction";
import background from '../../assets/room/salon/salon.jpg'

const Salon = ({setRoom, addictionFind, setAddictionFind, toggle}) => {

    const [isTiroirLeftSalon, setTiroirLeftSalon] = useState(false)
    const [isTiroirRightSalon, setTiroirRightSalon] = useState(false)
    const [isFreeBox, setIsFreeBox] = useState(false)
    const [isManette, setManette] = useState(false)
    const [isCarnet, setCarnet] = useState(false)
    const [isPopUpAddiction, setPopUpAddiction] = useState(false)
    const [textAddiction, setTextAddiction] = useState()

    const teleFree = useRef()   
    const modal = useRef(null)
    const pcPortableJeuxRef = useRef(null)

    // TELE FREE

    function displayTeleFree(){
        teleFree.current.classList.remove('notDisplay')
    }
    function notDisplayTeleFree(){
        teleFree.current.classList.add('notDisplay')
    }

    // CARNET TABLE

    function closeModal(){
        modal.current.classList.add('unDisplayed')
        pcPortableJeuxRef.current.classList.add('unDisplayed')
    }

    function openPcPortable(){ 
        modal.current.classList.remove('unDisplayed') 
        pcPortableJeuxRef.current.classList.remove('unDisplayed') ;
    }

    function findJeux(){
        let addictionImg =  document.getElementById('jeux')
        addictionImg.classList.remove('displayNone')
        if(!addictionFind.includes('jeux')){
            setPopUpAddiction(true)
            const text = returnText('jeuxText')
            setAddictionFind( addictionFind => [...addictionFind, 'jeux'] )
            setTextAddiction(text)
            closeModal()
            toggle()
        }
    }

        return (
            <div className="salonContainer roomContainer" >
                <div className="doorToCuisine onClick" onClick={() => setRoom('cuisine')} ></div>
                
                <img src={arrow} className="arrowBack" alt="flèche retour en arrière" onClick={() => setRoom('entree')} />
                
                <div className="teleContainer onClick" onClick={displayTeleFree}  >  </div>
                <img src={teleFreeImg} alt="télé Free " className="teleFree notDisplay" ref={teleFree} onClick={notDisplayTeleFree} />

                <div className="carnetContainer onClick" onClick={() => setCarnet(true)}></div>
                <div className="tiroirLeftContainerSalon onClick" onClick={() => setTiroirLeftSalon(true)}></div>
                <div className="tiroirRightContainerSalon onClick" onClick={() => setTiroirRightSalon(true)}></div>
                <div className="freeBoxContainer onClick" onClick={() => setIsFreeBox(true)} ></div>
                <div className="manetteContainer onClick" onClick={() => setManette(true)} ></div>

                <div className="pcContainerSalon onClick" onClick={openPcPortable} ></div>

                <div className="unDisplayed modal" ref={modal} >
                    <img src={redCross} alt="croix rouge" className="closeModal" onClick={closeModal} width='20' />
                    <img src={pcPortableJeux} alt="ordinateur portable écran jeux poker" ref={pcPortableJeuxRef} className="unDisplayed pcImgModal" onClick={findJeux} />
                </div>

                {isTiroirLeftSalon ? <PopUpImg img={tiroirLeftImg} set={setTiroirLeftSalon} />  :  <div></div>   } 
                {isTiroirRightSalon ? <PopUpImg img={tiroirRightImg} set={setTiroirRightSalon} />  :  <div></div>   } 
                {isFreeBox ? <PopUpImg img={freeBoxImg} set={setIsFreeBox} /> : <div></div>}   
                {isManette ? <PopUpImg img={manetteImg} set={setManette} /> : <div></div>}   
                {isCarnet ? <PopUpImg img={carnetImg} set={setCarnet} /> : <div></div>}

                {isPopUpAddiction ? <PopUpAddiction src={pcPortableJeux} text={textAddiction} set={setPopUpAddiction} toggle={toggle}/> : null}

            </div>
        )
    

}

export default Salon