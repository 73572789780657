import React, { useState } from "react";
import logoFree from '../assets/logoFree.jpg'
import pageAcceuil from '../assets/pageAccueil.jpg'
import './acceuil.css'

const Acceuil = ({setIsGameActive}) => {

    const [isSecondPage, setSecondPage] = useState(false)
    const [isThirdPage, setThirdPage] = useState(false)
    const [isMentions, setMentions] = useState(false)

    function goToThirdPage(){
        setThirdPage(true)
        setSecondPage(false)
    }

    if(isSecondPage){
        return(
            <div className="consignePage" >
                 <img src={logoFree} alt="" className="logo" />               
                <h1>Règles du jeu</h1>
                <p>
                    Profitez de l'absence de la famille Zadikt pour visiter leur maison et identifier leurs addictions. 
                    Pour cela, cliquez sur tous les éléments qui vous paraissent intéressants ou qui pourraient cacher une addiction.
                    Lorsqu'un objet s'ouvre dans une petite fenêtre, n'hésitez pas à approfondir vos recherches, pour découvrir les indices cachés.
                    Dès que vous avez trouvé une addiction, celle-ci vient s'ajouter dans un encart à droite de votre écran.
                <br/>
                    Les addictions de la famille se déclenchent en cliquant sur un élément en particulier. Si vous avez découvert une addiction mais qu'elle ne s'affiche pas dans un encart (à droite de l'écran), continuez vos recherches, vous n'en êtes pas loin !
                </p>
                <div className="strongP" >
                    Il y a bien plus de risques d'addiction que ce que l'on croit.<br/>
                    Bonnes recherches ...<br/> 
                </div>
                  
            <button onClick={goToThirdPage} >JOUER</button>
            </div>
        )
    }

    if(isThirdPage){
        return(
            <div className="secondPageContainer">
            <div className="secondPage">
                <img src={pageAcceuil} alt="" />
                <div className="btnStartContainer" onClick={() => setIsGameActive(true)} ></div>
            </div>
            </div>
        )
    }

    if(isMentions){

        return(

            <div className="mentionsPage" >

            <img src={logoFree} alt="" className="logo" />     

                <h1>Editeur : iliad</h1>
                <p>Société anonyme au capital de 13 102 539,32 €
                N° RCS Paris : 342 376 332
                N° TVA intracommunautaire : FR 82 342 376 332
                </p>

                <h1>Directeur de la publication</h1>
                <p>Monsieur Thomas Reynaud</p>

                <h1>Hébergeur : GANDI</h1>
                <p>Société anonyme par actions simplifiée au capital de 630 000 €
                N° RCS Paris : 423 093 459
                Siège social : 63, Boulevard Massena - 75013 Paris
                Téléphone : 08 99 03 03 03
                </p>

                <button onClick={() => setMentions(false)} >retour au jeu</button>
           
            </div>
        )
    }

    return (

        <div className="firstPageAcceuil" >
            <img src={logoFree} alt="" className="logo" />


            <h1>Addiction : asservissement d'un sujet à une substance ou une activité dont il a contracté l'habitude par un usage répété <br/> et dont l'absence provoque un manque,</h1>

            <p>Les addictions sont un problème de santé publique majeur.<br />
            Ses impacts sont multiples (sanitaires, médicaux, sociaux et donc professionnels).<br />
            </p>


            <p>Il est important de prévenir les pratiques à risques<br />
            pour limiter ce phénomène et ses conséquences.</p>

            <h1>Agissons ensemble pour instaurer une culture positive de la sécurité et de la santé ! </h1>

            <button onClick={() => setSecondPage(true)} className="buttonAcceuil" >Je découvre le jeu</button>
            <div className="mentions onClick" onClick={() => setMentions(true)}  >mentions légales</div>
        </div>

    )
}


export default Acceuil