import React, { useRef, useEffect, useState } from "react";
import redCross from '../../assets/redCross.png'
import eclairCode from '../../assets/eclairCode.png'
const ModalCodeBureau = ({setRoom, setCodeModal, setEnigmeFind , enigmeFind}) => {

    const inputContent = useRef()
    const inputContent1 = useRef()
    const inputContent2 = useRef()
    const inputContent3 = useRef()
    const inputContent4 = useRef()
    const inputContent5 = useRef()
    const inputContent6 = useRef()    
    const inputContent7 = useRef()    
    const wrongCode = useRef()

    function handleChange(e){
        let codeTest = inputContent.current.value +  inputContent1.current.value +  inputContent2.current.value +  inputContent3.current.value +  inputContent4.current.value +  inputContent5.current.value +  inputContent6.current.value +inputContent7.current.value
        if (codeTest.length === 8){
            if( codeTest == '21072001' ){
                setCodeModal(false)
                setRoom('bureau')
                setEnigmeFind( enigmeFind => [...enigmeFind, 'bureau'] )
            } 
            else{
                wrongCode.current.classList.remove('unDisplayed')
                
            }
        }
                
                const { maxLength, value, name } = e.target;
                const [fieldName, fieldIndex] = name.split("-");
              
                // Check if they hit the max character length
                if (value.length >= maxLength) {
                  // Check if it's not the last input field
                  if (parseInt(fieldIndex, 10) < 8) {
                    // Get the next input field
                    const nextSibling = document.querySelector(
                      `input[name=n-${parseInt(fieldIndex, 10) + 1}]`
                    );
              
                    // If found, focus the next field
                    if (nextSibling !== null) {
                      nextSibling.focus();
                    }
                  }
                }
    }

    function closeModal(){
        setCodeModal(false)
    }

    return (

        <div className='modalCodeBureauContainer'>

            <img src={redCross} alt="" className="redCross" width={20} onClick={closeModal}/>

            <p>Vous devez entrer le bon code pour entrer dans le bureau</p>

            <div className="inputContainer" >
                <img src={eclairCode} alt="" />
                <input type="text" autoFocus name="n-1"  maxLength='1'  ref={inputContent} onChange={handleChange} />
                <input type="text"  name="n-2" maxLength='1'  ref={inputContent1} onChange={handleChange}  />
                <input type="text"  name="n-3" maxLength='1'  ref={inputContent2}  onChange={handleChange} />
                <input type="text"  name="n-4" maxLength='1'  ref={inputContent3} onChange={handleChange} />
                <input type="text"   name="n-5" maxLength='1'  ref={inputContent4} onChange={handleChange}  />
                <input type="text"   name="n-6" maxLength='1'  ref={inputContent5}  onChange={handleChange} />
                <input type="text"   name="n-7" maxLength='1'  ref={inputContent6}  onChange={handleChange} />
                <input type="text"  name="n-8" maxLength='1'  ref={inputContent7}  onChange={handleChange} />
            </div>

            <div className="wrongCode unDisplayed" ref={wrongCode} >ce code n'est pas correct</div>

        </div>


    )
}


export default ModalCodeBureau