import React, { useState } from "react";
import arrow from '../../assets/backArrow.png'
import { useRef } from "react";
import tiroirBureauImg from '../../assets/room/bureau/tiroirBureau.png'

import './bureau.css'
import PopUpImg from "../../popUpImg/popUpImg";
import returnText from "../../function/textAddiction";
import PopUpAddiction from "../../popUpAddiction/popUpAddiction";
import redCross from '../../assets/redCross.png'
import addictionAlcool from '../../assets/room/bureau/addictionAlccol.png'

import poemeImg from '../../assets/room/bureau/poeme.png'
import vaseImg from '../../assets/room/bureau/vase.png'

import afficheImg from '../../assets/room/bureau/affiche.png'
import postItRose from '../../assets/room/bureau/postItRose.png'
import postItJaune from '../../assets/room/bureau/postItJaune.png'
import pcImg from '../../assets/room/bureau/pc.png'
import background from '../../assets/room/bureau/bureau.jpg'

const Bureau = ({setRoom, setAddictionFind, addictionFind, toggle }) => {

        
    const modal = useRef(null)
    const modal2 = useRef(null)
    const tiroirBureauImgRef = useRef(null)
    const vaseBureauImgRef = useRef(null)
    const poemeImgRef = useRef(null)
    const papierBlancRef= useRef(null)

    const [isPopUpAddiction, setPopUpAddiction] = useState(false)
    const [textAddiction, setTextAddiction] = useState()

    const [isPc, setPc] = useState(false)
    const [isPostItRose, setPostItRose] = useState(false)
    const [isPostItJaune, setPostItJaune] = useState(false)
    const [isAffiche, setAffiche] = useState(false)


    function closeModal(){
        modal.current.classList.add('unDisplayed')
        tiroirBureauImgRef.current.classList.add('unDisplayed')
    }

    function closeModal2(){
        modal2.current.classList.add('unDisplayed')
        poemeImgRef.current.classList.add('unDisplayed')
    }

    function openTiroir(){ 
        modal.current.classList.remove('unDisplayed') 
        tiroirBureauImgRef.current.classList.remove('unDisplayed') ;
    }

    function openVase(){
        modal2.current.classList.remove('unDisplayed')
        vaseBureauImgRef.current.classList.remove('unDisplayed')
        papierBlancRef.current.classList.remove('unDisplayed')
    }

    function findPoeme(){
        poemeImgRef.current.classList.remove('unDisplayed')
        vaseBureauImgRef.current.classList.add('unDisplayed')
    }

    function findAlcool(){
        let addictionImg =  document.getElementById('alcool')
        addictionImg.classList.remove('displayNone')
        if(!addictionFind.includes('alcool')){
            setPopUpAddiction(true)
            const text = returnText('alcoolText')
            setTextAddiction(text)
            setAddictionFind( addictionFind => [...addictionFind, 'alcool'] )
            closeModal()
            toggle()
        }
        else{

        }
    }

    return (
        <div className="bureauContainer roomContainer" >
            <img src={arrow} className="arrowBack" alt="Fleche retour en arrière" onClick={() => setRoom('palier')} />

            <div className="tiroirBureauContainer onClick" onClick={openTiroir}>  </div>
            <div className="vaseBureauContainer onClick" onClick={openVase} ></div>
        
            <div className="unDisplayed modal" ref={modal} >
                    <img src={redCross} alt="croix rouge" className="closeModal" onClick={closeModal} width='20' />
                    <img src={tiroirBureauImg} alt="tiroir bureau" ref={tiroirBureauImgRef} className="unDisplayed imgModal onClick" onClick={findAlcool} />
            </div>

            <div className="unDisplayed modalPoeme" ref={modal2} >
                    <img src={redCross} alt="croix rouge" className="closeModal" onClick={closeModal2} width='20' />
                    <img src={vaseImg} alt="vase" ref={vaseBureauImgRef} className="imgModal onClick" onClick={findPoeme}/>
                    <img src={poemeImg} alt="poème" ref={poemeImgRef} className='unDisplayed poemeModal'/>
            </div>

            <div className="afficheContainer1 onClick " onClick={() => setAffiche(true)} ></div>
            <div className="afficheContainer2 onClick " onClick={() => setAffiche(true)}></div>
            <div className="postItRoseContainer onClick" onClick={() => setPostItRose(true)} ></div>
            <div className="postItJauneContainer onClick" onClick={() => setPostItJaune(true)} ></div>
            <div className="pcContainer onClick " onClick={() => setPc(true)}  ></div>

            {isPc ? <PopUpImg img={pcImg} set={setPc} />  :  null   } 
            {isAffiche ? <PopUpImg img={afficheImg} set={setAffiche} />  :  null   } 
            {isPostItJaune ? <PopUpImg img={postItJaune} set={setPostItJaune} />  :  null   } 
            {isPostItRose ? <PopUpImg img={postItRose} set={setPostItRose} />  :  null   } 


            {isPopUpAddiction ? <PopUpAddiction src={addictionAlcool} text={textAddiction} set={setPopUpAddiction} toggle={toggle} />  : null}
        </div>
    )

}

export default Bureau