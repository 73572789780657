const jeuxText = <div>  
                    <h1>Addiction aux jeux</h1>
                            <h2>L&rsquo;addiction li&eacute;e aux jeux d&rsquo;argent et aux paris sportifs est moins &eacute;tudi&eacute;e. Cependant, environ 1&nbsp;joueur sur 10 a une pratique &agrave; risque ou est un joueur excessif.&nbsp;</h2>
                            <p>Les joueurs pathologiques pratiquent des jeux de hasard pur (roulette, machines &agrave; sous) ou des jeux m&ecirc;lant hasard et strat&eacute;gie (paris sportifs, poker, blackjack). Le point de d&eacute;part est toujours un gain initial qui g&eacute;n&egrave;re une &eacute;motion tr&egrave;s positive et incite &agrave; rejouer dans le but de revivre ce moment &laquo; magique &raquo;. Les pertes successives incitent le joueur &agrave; retenter inlassablement sa chance en augmentant les mises &agrave; mesure que les pertes s&rsquo;accroissent.&nbsp;</p>
                            <p>L&rsquo;addiction au jeu peut affecter tous les domaines de la vie, en d&eacute;stabilisant&nbsp;l&rsquo;&eacute;quilibre financier, la sant&eacute; physique et psychologique, les relations familiales et amicales et parfois l&rsquo;activit&eacute; professionnelle.&nbsp;</p>
                            <h3>Si vous &ecirc;tes concern&eacute; ou connaissez un proche ou un coll&egrave;gue ayant un probl&egrave;me avec le jeu, contactez un m&eacute;decin ou une association comme Joueurs-Info-Service au 09 74 75 13 13.</h3>
                            <div className="sources">
                            <p> Joueurs-Info-Service.fr. Qu&rsquo;est-ce que le jeu probl&eacute;matique&nbsp;? Disponible sur&nbsp;:&nbsp;<a target="_blank" href="https://www.joueurs-info-service.fr/Tout-savoir-sur-le-jeu/Le-jeu-problematique/Qu-est-ce-que-le-jeu-problematique">https://www.joueurs-info-service.fr/Tout-savoir-sur-le-jeu/Le-jeu-problematique/Qu-est-ce-que-le-jeu-problematique</a> Consult&eacute; le 5 avril 2022.</p>
                            <p>INSERM. Addictions. Du plaisir &agrave; la d&eacute;pendance. Disponible sur :&nbsp;<a target="_blank" href="https://www.inserm.fr/dossier/addictions/">https://www.inserm.fr/dossier/addictions/</a> Consult&eacute; le 5 avril 2022.</p>
                            </div>
                </div>

const medicamentsText = <div>
                           <h1>Addiction aux m&eacute;dicaments</h1>
                                <h2>L&rsquo;addiction m&eacute;dicamenteuse concerne certes les substances notoirement addictives comme les somnif&egrave;res (benzodiaz&eacute;pines) et les agents morphiniques mais aussi des m&eacute;dicaments courants comme les antalgiques &agrave; base de parac&eacute;tamol, les laxatifs, ou m&ecirc;me les d&eacute;congestionnants nasaux.</h2>
                                <div><p>La d&eacute;pendance physique montre que le corps s&rsquo;est habitu&eacute; &agrave; un m&eacute;dicament et manifeste des sympt&ocirc;mes s&rsquo;il en est priv&eacute;.&nbsp;</p>
                                <p>Le sujet d&eacute;pendant &agrave; un m&eacute;dicament peut donc avoir tendance &agrave; augmenter les doses ou la fr&eacute;quence des prises prescrites. Il peut passer un certain temps &agrave; essayer d&apos;obtenir&nbsp;son m&eacute;dicament, comme multiplier les consultations aupr&egrave;s de diff&eacute;rents m&eacute;decins pour avoir plus d&rsquo;ordonnances. Lorsqu&rsquo;un m&eacute;dicament alt&egrave;re les capacit&eacute;s &agrave; la conduite ou &agrave; l&rsquo;utilisation de machines, un des trois pictogrammes suivants apparait obligatoirement sur la boite de celui-ci.</p></div>
                                <p className="imgMedicaments" ><img alt="Médicaments Et Conduite : Effets Et Risques | EVS - code de la route en  ligne" src="https://lh6.googleusercontent.com/z_uLE85qCXug1RsURSeNQV-ybLLl5Yxq35erBMraeKFj-qUmzfOQ41uJkguHDalewhta-oorgqSeLPxFkHn_Wz4wnbYzfodI7u2UQqJD7i3110xg0dukoz5RHuO4SqHnOf1IFT96" width="363" height="154"/></p>
                                <h3>Si vous &ecirc;tes concern&eacute; ou connaissez un proche ou un coll&egrave;gue ayant un probl&egrave;me avec les m&eacute;dicaments, contactez un m&eacute;decin ou une association comme Addictions France au 01 42 33 51 04 qui pourra vous orienter vers un Centres de Soins d&rsquo;Accompagnement et de Pr&eacute;vention en Addictologie.</h3>
                                <div className="sources">
                                <p> Sant&eacute;.fr Addiction m&eacute;dicamenteuse, suis-je concern&eacute; ? Publi&eacute; le 12/08/2019. Disponible sur :&nbsp;<a target="_blank" href="https://www.sante.fr/addiction-medicamenteuse-suis-je-concerne">https://www.sante.fr/addiction-medicamenteuse-suis-je-concerne</a> Consult&eacute; le 5 avril 2022.</p>
                                <p>Minist&egrave;re de l&rsquo;Int&eacute;rieur. Les m&eacute;dicaments et la conduite. Disponible sur :&nbsp;<a target="_blank" href="https://www.securite-routiere.gouv.fr/dangers-de-la-route/les-medicaments-et-la-conduite">https://www.securite-routiere.gouv.fr/dangers-de-la-route/les-medicaments-et-la-conduite</a> Consult&eacute; le 5 avril 2022.</p>
                                </div>
                            </div>
                        
const alcoolText = <div>
                        <h1>Addiction &agrave; l&rsquo;alcool</h1>
                            <h2>L&rsquo;addiction &agrave; l&rsquo;alcool concerne 8% de la population d&rsquo;apr&egrave;s l&rsquo;Office Fran&ccedil;ais des D&eacute;pendances et Toxicomanies (OFDT).&nbsp;</h2>
                            <p>On parle de consommation &laquo; &agrave; risque &raquo; lorsque la quantit&eacute; d&rsquo;alcool ing&eacute;r&eacute;e et la fr&eacute;quence des prises est importante. Si ce comportement persiste sur une dur&eacute;e prolong&eacute;e, des complications physiques, psychiques et sociales surviendront in&eacute;vitablement. La valeur rep&egrave;re unique (homme et femme) est de 10 verres d&apos;alcool standard par semaine, maximum. Pour m&eacute;moire : pour la sant&eacute;, l&rsquo;alcool c&rsquo;est maximum 2 verres par jour et pas tous les jours.&nbsp;</p>
                            <p>L&rsquo;addiction &agrave; l&rsquo;alcool peut avoir des cons&eacute;quences sur la vie familiale, relationnelle et professionnelle. De plus, la conduite apr&egrave;s une prise excessive d&rsquo;alcool multiplie par 8,5 le risque d&rsquo;&ecirc;tre responsable d&rsquo;un accident mortel.</p>
                            <h3>Si vous &ecirc;tes concern&eacute; ou connaissez un proche ou un coll&egrave;gue ayant un probl&egrave;me avec l&rsquo;alcool, contactez un m&eacute;decin ou une association comme Alcool-Info-Service au 0 980 980&nbsp;930.</h3>
                            <div className="sources" >
                            <p> Alcool-Info-Service.fr. L&rsquo;addiction &agrave; l&rsquo;alcool. Disponible sur&nbsp;:&nbsp;<a target="_blank" href="https://www.alcool-info-service.fr/alcool/evaluer-consommation-alcool/dependance-alcoolique#.XIuLyyJKhhE">https://www.alcool-info-service.fr/alcool/evaluer-consommation-alcool/dependance-alcoolique#.XIuLyyJKhhE</a> Consult&eacute; le 5 avril 2022.</p>
                            <p>INSERM. Addictions. Du plaisir &agrave; la d&eacute;pendance. Disponible sur :&nbsp;<a target="_blank" href="https://www.inserm.fr/dossier/addictions/">https://www.inserm.fr/dossier/addictions/</a> Consult&eacute; le 5 avril 2022.</p>
                            </div>
                </div>


const phoneText = <div>
                    <h1>Addiction au t&eacute;l&eacute;phone portable / r&eacute;seaux sociaux</h1>
                        <h2>L&rsquo;addiction au t&eacute;l&eacute;phone portable ou aux r&eacute;seaux sociaux touche de plus en plus d&rsquo;adolescents et de jeunes adultes. </h2>
                        <div><p>Avec une moyenne de 5,6 &eacute;crans par foyer en France en 2020 et un &acirc;ge d&rsquo;acquisition de plus en plus pr&eacute;coce du premier portable (en majorit&eacute; avant 12 ans), la question du temps d&rsquo;&eacute;cran pose de plus en plus de probl&eacute;matiques. On parle d&eacute;sormais de nomophobie pour d&eacute;signer la peur d&rsquo;&ecirc;tre &eacute;loign&eacute; de son t&eacute;l&eacute;phone et de manquer une information importante.&nbsp;</p>
                        <p>L&rsquo;addiction au t&eacute;l&eacute;phone pousse &agrave; renoncer &agrave; d&rsquo;autres activit&eacute;s, &agrave; moins voir ses proches, &agrave; s&rsquo;exclure de la vie sociale voire &agrave; souffrir de d&eacute;pression, d&rsquo;anxi&eacute;t&eacute; accrue et de troubles du sommeil.&nbsp;</p></div>
                        <p>S&rsquo;imposer une d&eacute;connexion quotidienne pendant les repas, dans la chambre ou ne plus avoir son portable dans sa poche peut aider &agrave; limiter son usage. Il est aussi essentiel de limiter sa connexion, notamment aux emails en dehors des horaires de travail (transport, repos, week-end, soir&eacute;es&hellip;).</p>
                        <h3>Si vous &ecirc;tes concern&eacute; ou connaissez un proche ou un coll&egrave;gue ayant un probl&egrave;me avec l&rsquo;utilisation du t&eacute;l&eacute;phone portable ou des r&eacute;seaux sociaux, contactez un m&eacute;decin ou une association comme Fil Sant&eacute; Jeunes au 0 800 235 236 ou au 01 44 93 30 74.</h3>
                        <div className="sources">
                        <p>Pourquoi Docteur. Nomophobie : comment d&eacute;crocher de son t&eacute;l&eacute;phone portable ? Disponible sur :&nbsp;<a target="_blank" href="https://www.pourquoidocteur.fr/Articles/Question-d-actu/38547-Nomophobie-decrocher-telephone-portable">https://www.pourquoidocteur.fr/Articles/Question-d-actu/38547-Nomophobie-decrocher-telephone-portable</a>&nbsp; &nbsp;Consult&eacute; le 5 avril 2022.</p>
                        <p>INSERM. Addictions. Du plaisir &agrave; la d&eacute;pendance. Disponible sur :&nbsp;<a target="_blank" href="https://www.inserm.fr/dossier/addictions/">https://www.inserm.fr/dossier/addictions/</a> Consult&eacute; le 5 avril 2022.</p>
                    </div>
                </div>

const resineText = <div>
        <h1>Addiction aux drogues</h1>
        <h2>L&rsquo;addiction aux drogues est une pathologie qui repose sur la consommation r&eacute;p&eacute;t&eacute;e d&rsquo;un produit (cannabis, h&eacute;ro&iuml;ne, coca&iuml;ne, etc.)</h2>
        <p>Ces drogues perturbent le syst&egrave;me nerveux et ont un effet stimulant et hallucinog&egrave;ne. La dangerosit&eacute; des drogues est variable car elles peuvent provoquer une modification de l&rsquo;&eacute;quilibre &eacute;motionnel, des troubles d&rsquo;ordre m&eacute;dical ou des perturbations de la vie personnelle, professionnelle ou sociale.</p>
        <div><p>De plus, conduire apr&egrave;s la prise de drogues augmente le risque d&rsquo;accident mortel.</p>
        <p>Les facteurs individuels comme l&rsquo;&acirc;ge, le sexe, la maturit&eacute; c&eacute;r&eacute;brale, la personnalit&eacute; ou l&rsquo;humeur peuvent avoir une incidence sur la survenance de l&rsquo;addiction.</p>
        </div>
        <h3>Si vous &ecirc;tes concern&eacute; ou connaissez un proche ou un coll&egrave;gue ayant un probl&egrave;me avec les drogues, contactez un m&eacute;decin ou une association comme Drogues-Info-Service au 0 800 23 13 13.</h3>
        <div className="sources" >
        <p> Drogue-Info-Service.fr. Qu&rsquo;est-ce qu&rsquo;une drogue ? Disponible sur :&nbsp;<a target="_blank" href="https://www.drogues-info-service.fr/Tout-savoir-sur-les-drogues/Les-drogues-et-leur-consommation/Qu-est-ce-qu-une-drogue">https://www.drogues-info-service.fr/Tout-savoir-sur-les-drogues/Les-drogues-et-leur-consommation/Qu-est-ce-qu-une-drogue</a> Consult&eacute; le 5 avril 2022.</p>
        <p>INSERM. Addictions. Du plaisir &agrave; la d&eacute;pendance. Disponible sur :&nbsp;<a target="_blank" href="https://www.inserm.fr/dossier/addictions/">https://www.inserm.fr/dossier/addictions/</a> Consult&eacute; le 5 avril 2022.</p>
        </div>
</div>

const sexeText = <div>
    <h1>Addiction au sexe</h1>
        <h2>L&rsquo;addiction au sexe se caract&eacute;rise par&nbsp;une perte de contr&ocirc;le sur la sexualit&eacute;. Elle peut avoir diff&eacute;rentes pr&eacute;sentations cliniques (masturbation compulsive, drague compulsive, consultation compulsive de sites internet class&eacute;s X, de journaux, de services t&eacute;l&eacute;phoniques &agrave; caract&egrave;re pornographique, de sex shops, &hellip;)</h2>
        <p>Cette addiction concerne environ 5% de la population. Elle peut avoir des cons&eacute;quences n&eacute;gatives en menant &agrave; un isolement, ou, au contraire, &agrave; multiplier les partenaires. Les personnes qui en souffrent peuvent s&rsquo;exclure totalement de leur vie sociale et / ou professionnelle.</p> 
        <p>Elles peuvent d&eacute;velopper d&rsquo;autres comportements &agrave; risque tels que la consommation d&rsquo;alcool et de drogues ou le recours &agrave; des rapports non prot&eacute;g&eacute;s et &agrave; des actes violents &agrave; caract&egrave;re sexuel.</p>
        <h3>Si vous &ecirc;tes concern&eacute; ou connaissez un proche ou un coll&egrave;gue ayant un probl&egrave;me avec le sexe, contactez un m&eacute;decin ou une association comme Addictions France qui pourra vous orienter vers un Centres de Soins d&rsquo;Accompagnement et de Pr&eacute;vention en Addictologie.</h3>
        <div className="sources">
        <p> HUG. Addiction sexuelle. Disponible sur&nbsp;:&nbsp;<a target="_blank" href="https://www.hug.ch/addictologie/addiction-sexuelle#:~:text=L'addiction%20sexuelle%2C%20ou%20d%C3%A9pendance,%2C%20prises%20de%20risques%20infectieux">https://www.hug.ch/addictologie/addiction-sexuelle#:~:text=L&apos;addiction%20sexuelle%2C%20ou%20d%C3%A9pendance,%2C%20prises%20de%20risques%20infectieux</a>&nbsp; Consult&eacute; le 5 avril 2022.</p>
        <p>Essentiel Sant&eacute;. Addictions : les centres de soins, d&rsquo;accompagnement et de pr&eacute;vention en addictologie, des lieux pour se lib&eacute;rer. Mis en ligne le 30/11/2020. Disponible sur :&nbsp;<a target="_blank" href="https://www.essentiel-sante-magazine.fr/sante/traitements-soins/addictions-les-centres-de-soins-daccompagnement-et-de-prevention-en-addictologie-des-lieux-pour-se-liberer">https://www.essentiel-sante-magazine.fr/sante/traitements-soins/addictions-les-centres-de-soins-daccompagnement-et-de-prevention-en-addictologie-des-lieux-pour-se-liberer</a> Consult&eacute; le 5 avril 2022.</p>
        </div>
</div>

function returnText(whichText){
    const allText = {sexeText, medicamentsText, resineText, jeuxText, phoneText, alcoolText}

    if (whichText == 'sexeText'){return sexeText }
    if (whichText == 'medicamentsText'){return medicamentsText }
    if (whichText == 'resineText'){return resineText }
    if (whichText == 'jeuxText'){return jeuxText }
    if (whichText == 'phoneText'){return phoneText }
    if (whichText == 'alcoolText'){return alcoolText }
    if(whichText == 'all'){return allText}

}

export default returnText