import React from "react";
import { useState } from "react";
import arrow from '../../assets/backArrow.png'

import ModalCodeBureau from "./modalCodeBureau";

import background from '../../assets/room/palier/palier.jpg'

import './palier.css'

const Palier = ({setRoom , enigmeFind, setEnigmeFind}) => {

    const [codeModal, setCodeModal] = useState(false)    

    function checkEnigmeFind(){
        if(enigmeFind.includes('bureau')){
            setRoom('bureau')
        }
        else{
            setCodeModal(true)
        }
    }

    return (
        <div className="PalierContainer roomContainer" >
            <div className="doorPalierToSdb onClick" onClick={() => setRoom('sdb')} ></div>
            <div className="doorToChambre onClick" onClick={() => setRoom('chambre')} ></div>
            <div className="doorToBureau onClick" onClick={checkEnigmeFind} ></div>
            <img src={arrow} className="arrowBack onClick" alt="flèche retour en arrière" onClick={() => setRoom('entree') } />

            {codeModal ? <ModalCodeBureau setRoom={setRoom} setCodeModal={setCodeModal} enigmeFind={enigmeFind} setEnigmeFind={setEnigmeFind}/> : null } 

        </div>
    )


}

export default Palier