import React, {useState} from "react";
import Game from "../room/game";
import SensiMessage from "../endGame/sensiMessage";
import WinScreen from "../endGame/winScreen";
import LooseScreen from "../endGame/looseScreen";
import returnText from "../function/textAddiction";
import Acceuil from "./acceuil";

const Nav = () => {
    const [isWinScreen, setWinScreen] = useState(false)
    const [isSensiMessqge, setSensiMessage] = useState(false) 
    const [isLooseScreen, setLooseScreen] = useState(false)
    const [textSensi, setTextSensi] = useState()
    const [isGameActive, setIsGameActive] = useState(false)

    function setText(){
        setTextSensi(returnText('all'))
    }

  if(isSensiMessqge){

      return(
          <SensiMessage textSensi={textSensi} setSensiMessage={setSensiMessage} />
      )
  }
  if(isWinScreen){
      return(
            <WinScreen setText={setText} setSensiMessage={setSensiMessage} setWinScreen={setWinScreen} setLooseScreen={setLooseScreen}/>
            )
        }

    if(isLooseScreen){
        return(
            <LooseScreen setText={setText} setSensiMessage={setSensiMessage} setWinScreen={setWinScreen} setLooseScreen={setLooseScreen}/>
            )
    }
    if(isGameActive){
        return(
            <Game  setWinScreen={setWinScreen} setLooseScreen={setLooseScreen} />
        )
    }
    return(
        <Acceuil setIsGameActive={setIsGameActive} />

    )
}

export default Nav