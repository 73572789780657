import React from "react";
import losingImg from '../assets/losing.jpg'
import logoFree from '../assets/logoFree.jpg'
import './endGame.css'
import looseScreenImg from '../assets/looseScreen.jpg'

const LooseScreen = ({setSensiMessage, setLooseScreen, setText}) => {


    function displaySensiMessage(){
        setText()
        setSensiMessage(true)
        setLooseScreen(false)
    }

    function replay(){
        setLooseScreen(false)
        setWinScreen(false)
    }

    return (

        <div className="winLooseScreenContainer">
        <div className="winLooseScreen">
            <img src={looseScreenImg} alt="message de défaite" />
            <div className="btnMsgLooseContainer" onClick={displaySensiMessage} ></div>
            <div className="btnPlayAgain" onClick={replay} ></div>
        </div>
    </div>
    )

}

export default LooseScreen