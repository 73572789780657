import React from "react";

import './room.css'

import Entree from "./entree/entree";
import Palier from "./palier/palier";
import Salon from "./salon/salon";
import Sdb from "./sdb/sdb";
import Cuisine from "./cuisine/cuisine"
import Chambre from "./chambre/chambre"
import Bureau from "./bureau/bureau";


const WhichRoom = ({setRoom, room, setAddictionFind, addictionFind, enigmeFind, setEnigmeFind, toggle}) => {

    if(room == 'entree'){return(<Entree setRoom={setRoom} setAddictionFind={setAddictionFind} addictionFind={addictionFind} toggle={toggle} />) }
    if(room == 'chambre'){return(<Chambre toggle={toggle}  setRoom={setRoom} setAddictionFind={setAddictionFind} addictionFind={addictionFind} enigmeFind={enigmeFind} setEnigmeFind={setEnigmeFind} />)}
    if(room == 'sdb'){return(<Sdb toggle={toggle}  setRoom={setRoom} setAddictionFind={setAddictionFind} addictionFind={addictionFind}  enigmeFind={enigmeFind} setEnigmeFind={setEnigmeFind}  />)}
    if(room == 'bureau'){return(<Bureau toggle={toggle}  setRoom={setRoom} setAddictionFind={setAddictionFind} addictionFind={addictionFind} />)}
    if(room == 'cuisine'){return(<Cuisine  toggle={toggle}  setRoom={setRoom} setAddictionFind={setAddictionFind} addictionFind={addictionFind} />)}
    if(room == 'salon'){return(<Salon toggle={toggle}  setRoom={setRoom} setAddictionFind={setAddictionFind} addictionFind={addictionFind} />)}
    if(room == 'palier'){return(<Palier toggle={toggle}  setRoom={setRoom} setAddictionFind={setAddictionFind} addictionFind={addictionFind} enigmeFind={enigmeFind} setEnigmeFind={setEnigmeFind}/>)}

}


export default WhichRoom