import React, {useState, useRef} from "react";
import ModalSdbCode from "./modalSdbCode";
import './sdb.css'
import arrow from '../../assets/backArrow.png'

import armoireSdbImg from '../../assets/room/sdb/armoireSdb.png'
import redCross from '../../assets/redCross.png'
import returnText from "../../function/textAddiction";
import PopUpAddiction from "../../popUpAddiction/popUpAddiction";
import addictionMedicaments from '../../assets/room/sdb/addictionMedicaments.png'
import PopUpImg from "../../popUpImg/popUpImg";
import magazineH from '../../assets/room/sdb/magazineH.png'
import magazineF from '../../assets/room/sdb/magazineF.png'
import magazine from '../../assets/room/sdb/magazine.png'
import parfum from '../../assets/room/sdb/parfum.png'


const Sdb = ({setRoom, setAddictionFind, addictionFind,  enigmeFind, setEnigmeFind , toggle}) => {

    const [codeModal, setCodeModal] = useState(false)
    const armoireSdb = useRef(null)
    const modal = useRef(null)

    const [isPopUpAddiction, setPopUpAddiction] = useState(false)
    const [textAddiction, setTextAddiction] = useState()

    const [isParfum, setParfum] = useState(false)
    const [ isMagazineH, setMagazineH] = useState(false)
    const [isMagazineF, setMagazineF] = useState(false)
    const [isMagazine, setMagazine] = useState(false)

    function closeModal(){
        modal.current.classList.add('unDisplayed')
    }

    function openArmoire(){
        modal.current.classList.remove('unDisplayed')
    }

    function findMedicaments(){
        let addictionImg =  document.getElementById('medicaments')
        addictionImg.classList.remove('displayNone')
        if(!addictionFind.includes('medicaments')){
            setPopUpAddiction(true)
            const text = returnText('medicamentsText')
            setAddictionFind( addictionFind => [...addictionFind, 'medicaments'] )
            setTextAddiction(text)
            closeModal()
            toggle()
        }
    }

    function checkEnigmeFind(){
        if(enigmeFind.includes('sdb')){
            openArmoire()
        }
        else{
            setCodeModal(true)
        }
    }

    function goToPalier(){setRoom('palier')}

    return (
        <div className="SdbContainer roomContainer" >
            <img className="arrowBack onClick" src={arrow} alt="flèche retour en arrière" onClick={() => setRoom('palier')}  />
            <div className="doorSdbToPalier onClick" onClick={goToPalier} ></div>

            <div className="armoireContainer onClick " onClick={checkEnigmeFind} ></div>


            <div className="unDisplayed modal  " ref={modal} >
                <img src={redCross} alt="croix rouge" className="closeModal" onClick={closeModal} width='20' />
                <img src={armoireSdbImg} alt="armoire à pharmacie" ref={armoireSdb} className="imgModal onClick" onClick={findMedicaments} />
            </div>

            <div className="magazineFContainer onClick" onClick={() => setMagazineF(true)}  ></div>
            <div className="magazineHContainer onClick" onClick={() => setMagazineH(true)}  ></div>
            <div className="magazineContainer onClick" onClick={() => setMagazine(true)} ></div>
            <div className="parfumContainer onClick" onClick={() => setParfum(true)}  ></div>

            {isMagazineH ? <PopUpImg img={magazineH} set={setMagazineH} />  :  null   } 
            {isMagazineF ? <PopUpImg img={magazineF} set={setMagazineF} />  :  null   } 
            {isParfum ? <PopUpImg img={parfum} set={setParfum} />  :  null   } 
            {isMagazine ? <PopUpImg img={magazine} set={setMagazine} />  :  null   } 

            {codeModal ? <ModalSdbCode setCodeModal={setCodeModal} openArmoire={openArmoire} enigmeFind={enigmeFind} setEnigmeFind={setEnigmeFind} /> : null } 
            {isPopUpAddiction ? <PopUpAddiction src={addictionMedicaments} text={textAddiction} set={setPopUpAddiction} toggle={toggle}/> : null}

        </div>
    )


}

export default Sdb