import React from "react";
import { useRef, useEffect, useState } from "react";
import './chambre.css'

import arrow from '../../assets/backArrow.png'

import tiroirLeftImg from '../../assets/room/chambre/tiroirLeft.png'
import tiroirRightImg from '../../assets/room/chambre/tiroirRight.png'
import valiseImg from '../../assets/room/chambre/valiseOuverte.png'
import PopUpImg from "../../popUpImg/popUpImg";
import photoChambreImg from "../../assets/room/chambre/photoChambre.png"
import addictionSexe from '../../assets/room/chambre/addictionSexe.png'
import ModalCodeChambre from "./modalCodeChambre";
import redCross from '../../assets/redCross.png'
import PopUpAddiction from "../../popUpAddiction/popUpAddiction";
import returnText from "../../function/textAddiction";

import carteChambreImg from '../../assets/room/chambre/carte.png'
import tapisChambreImg from '../../assets/room/chambre/tapis.png'
import barreChambreImg from '../../assets/room/chambre/barre.png'
import priseChambreImg from '../../assets/room/chambre/prise.png'

import background from '../../assets/room/chambre/chambre.jpg'

const Chambre = ({setRoom, setAddictionFind, addictionFind, enigmeFind, setEnigmeFind, toggle }) => {

    const valiseImgRef = useRef(null)
    const modal = useRef(null)

    const[ isTiroirLeftChambre, setTiroirLeftChambre ] = useState(false)
    const[ isTiroirRightChambre, setTiroirRightChambre ] = useState(false)
    const[ isPhotoChambre, setPhotoChambre ] = useState(false)
    const [isModalValise, setModalValise] = useState(false)
    const [isTapisChambre, setTapisChambre] = useState(false)
    const [isPriseChambre, setPriseChambre] = useState(false)
    const [isBarreChambre, setBarreChambre] = useState(false)
    const [isCarteChambre, setCarteChambre] = useState(false)

    const [isPopUpAddiction, setPopUpAddiction] = useState(false)
    const [textAddiction, setTextAddiction] = useState()
 
    const [codeFind, setCodeFind] = useState(false)


    function closeModal(){ modal.current.classList.add('unDisplayed')}

    function openValise(){modal.current.classList.remove('unDisplayed')}

    function findSexe(){
        let addictionImg =  document.getElementById('sexe')
        addictionImg.classList.remove('displayNone')
        
        if(!addictionFind.includes('sexe')){
            setPopUpAddiction(true)
            const text = returnText('sexeText')
            setTextAddiction(text)
            setAddictionFind( addictionFind => [...addictionFind, 'sexe'] )
            closeModal()
            toggle()
        }
    }

    function checkEnigmefind(){

        if(enigmeFind.includes('valise')){
            openValise()
        }
        else{
            setModalValise(true)
        }
    }


    return (
        <div className="chambreContainer roomContainer" >
            <img src={arrow} className="arrowBack onClick" alt="flèche retour en arrière" onClick={() => setRoom('palier')} />

            <div className="valise onClick" onClick={checkEnigmefind} ></div>

            <div className="unDisplayed modal  " ref={modal} >
                <img src={redCross} alt="croix rouge" className="closeModal" onClick={closeModal} width='20' />
                <img src={valiseImg} alt="valise" ref={valiseImgRef} className="valiseModal"  />
                <div className="sexeAddictionContainer1 onClick" onClick={findSexe}></div>
                <div className="sexeAddictionContainer2 onClick" onClick={findSexe} ></div>
                <div className="carteChambreContainer onClick" onClick={() => setCarteChambre(true)} ></div>
                <div className="barreChambreContainer onClick" onClick={() => setBarreChambre(true)}></div>
            </div>

            <div className="tiroirLeftContainerChambre onClick" onClick={() => setTiroirLeftChambre(true)}></div>
            <div className="tiroirRightContainerChambre onClick" onClick={() => setTiroirRightChambre(true)}></div>
            <div className="photoChambreContainer onClick" onClick={() => setPhotoChambre(true)}></div>
            <div className="priseChambreContainer onClick" onClick={() => setPriseChambre(true)}></div>
            <div className="tapisChambreContainer onClick" onClick={() => setTapisChambre(true)}></div>
        
            {isModalValise ? <ModalCodeChambre setRoom={setRoom} setCodeModal={setModalValise} openValise={openValise} setEnigmeFind={setEnigmeFind} enigmeFind={enigmeFind}/> : null}


            {isPhotoChambre ? <PopUpImg img={photoChambreImg} set={setPhotoChambre} />  :  null   } 
            {isTiroirLeftChambre ? <PopUpImg img={tiroirLeftImg} set={setTiroirLeftChambre} />  :  null   } 
            {isTiroirRightChambre ? <PopUpImg img={tiroirRightImg} set={setTiroirRightChambre} />  :  null   } 
            
            {isBarreChambre ? <PopUpImg img={barreChambreImg} set={setBarreChambre} />  :  null   } 
            {isPriseChambre ? <PopUpImg img={priseChambreImg} set={setPriseChambre} />  :  null   } 
            {isTapisChambre ? <PopUpImg img={tapisChambreImg} set={setTapisChambre} />  :  null   } 
            {isCarteChambre ? <PopUpImg img={carteChambreImg} set={setCarteChambre} />  :  null   } 

            {isPopUpAddiction ? <PopUpAddiction src={addictionSexe} text={textAddiction} set={setPopUpAddiction} toggle={toggle}/> : null}

        </div>
    )


}

export default Chambre