import React, { useEffect } from "react";
import './popUpAddiction.css'
import handshake from '../assets/handshake.png'

import redCross from '../assets/redCross.png'

const PopUpAddiction = ({src, text, set, toggle}) => {


    function closePopUp(){
        set(false)
        toggle()
    }

    return (

        <div className="popUpAddictionContainer" >
            <img src={redCross} alt="" className="redCross" onClick={closePopUp}   />

            <div className="popUpAddictionContent" >

                <div className="headerPopUp" >
                    <div><img src={src} alt=""  className='imgPopUpAddiction'/></div>
                    <div >{text.props.children[0]}{text.props.children[1]}</div>

                </div>
                <div>
                    {text.props.children[2]}
                    {text.props.children[3]}

                    <hr/>
                    <div className="redMessage" >
                        <div className="handShakeContainer"><img src={handshake} width='50' className="handshake" /></div>
                        <div>{text.props.children[4]}</div>
                    </div>
                   
                </div>

                
            </div>
        </div>

    )


}


export default PopUpAddiction