import React, { useRef, useEffect, useState } from "react";
import redCross from '../../assets/redCross.png'


const ModalSdbCode = ({openArmoire, setCodeModal,  setEnigmeFind, enigmeFind}) => {

    const inputContent = useRef()
    const inputContent1 = useRef()
    const inputContent2 = useRef()
    const wrongCode = useRef()

    function handleChange(e){
        let codeTest = inputContent.current.value.toUpperCase() +  inputContent1.current.value.toUpperCase() +  inputContent2.current.value.toUpperCase()
        if (codeTest.length === 3){
            if( codeTest == 'SST'){
                setCodeModal(false)
                openArmoire()
                setEnigmeFind( enigmeFind => [...enigmeFind, 'sdb'] )
            } 
            else{
                wrongCode.current.classList.remove('unDisplayed')
            }
        }
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
      
        // Check if they hit the max character length
        if (value.length >= maxLength) {
          // Check if it's not the last input field
          if (parseInt(fieldIndex, 10) < 3) {
            // Get the next input field
            const nextSibling = document.querySelector(
              `input[name=n-${parseInt(fieldIndex, 10) + 1}]`
            );
      
            // If found, focus the next field
            if (nextSibling !== null) {
              nextSibling.focus();
            }
          }
        }
    }

    function closeModal(){
        setCodeModal(false)
    }
    return (

        <div className='modalCodeSdbContainer'>

            <img src={redCross} alt="" className="redCross" width={20} onClick={closeModal}/>

            <p>Vous devez entrer le bon code pour ouvrir l'armoire à pharmacie</p>

            <div className="inputContainer" >
                <input type="text" autoFocus   ref={inputContent} name='n-1' maxLength='1' onChange={handleChange} className="redInput" />
                <input type="text"   ref={inputContent1} name='n-2' maxLength='1' onChange={handleChange} className="blueInput" />
                <input type="text"   ref={inputContent2} name='n-3'  maxLength='1' onChange={handleChange} className="yellowInput"/>
            </div>

            <span>J'ai cru voir des lettres encadrées en couleur...</span>

            <div className="wrongCode unDisplayed" ref={wrongCode} >ce code n'est pas correct</div>


        </div>


    )
}


export default ModalSdbCode