import React from "react";
import { useState, useRef } from "react";

import './cuisine.css'
import arrow from '../../assets/backArrow.png'

import frigoUp from '../../assets/room/cuisine/frigoUp.png'
import frigoDown from '../../assets/room/cuisine/frigoDown.png'

import phone from '../../assets/room/cuisine/phone.png'
import redCross from '../../assets/redCross.png'

import phoneOpen from '../../assets/room/cuisine/phoneOpen.png'
import phoneAddiction from '../../assets/room/cuisine/addictionPhone.png'

import PopUpAddiction from "../../popUpAddiction/popUpAddiction";
import PopUpImg from "../../popUpImg/popUpImg";
import returnText from "../../function/textAddiction";

// elements Cliquables
import avoine from '../../assets/room/cuisine/avoine.png'
import prunneaux from '../../assets/room/cuisine/prunneaux.png'
import son from '../../assets/room/cuisine/son.png'
import postIt from '../../assets/room/cuisine/postIt.png'
import poisChiche from '../../assets/room/cuisine/poisChiche.png'
import lentille from '../../assets/room/cuisine/lentille.png'
import noix from '../../assets/room/cuisine/noix.png'
import haricots from '../../assets/room/cuisine/haricots.png'


const Cuisine = ({setRoom , setAddictionFind, addictionFind, toggle}) => {

    const frigoUpRef = useRef(null)
    const frigoDownRef = useRef(null)
    const phoneOpenRef = useRef(null)
    const phoneAddictionRef = useRef(null)
    const modal = useRef(null)
    const tempsEcranRef = useRef(null)

    const [isPopUpAddiction, setPopUpAddiction] = useState(false)
    const [textAddiction, setTextAddiction] = useState()

    // elem Cliquables

    const [isAvoine, setAvoine] = useState(false)
    const [isSon, setSon] = useState(false)
    const [isPostit, setPostIt] = useState(false)
    const [isPoisChiche, setPoisChiche] = useState(false)
    const [isLentille, setLentille] = useState(false)
    const [isNoix, setNoix] = useState(false)
    const [isHaricots, setHaricots] = useState(false)
    const [isPrunneaux, setPrunneaux] = useState(false)

    function closeModal(){
        modal.current.classList.add('unDisplayed')
        phoneAddictionRef.current.classList.add('unDisplayed')
    }

    function clickOnPhone(){
        modal.current.classList.remove('unDisplayed') 
        phoneOpenRef.current.classList.remove('unDisplayed') ;
        tempsEcranRef.current.classList.remove('unDisplayed') ;
    }

    function switchToAddiction(){ 
        phoneOpenRef.current.classList.add('unDisplayed') ;
        tempsEcranRef.current.classList.add('unDisplayed');
        phoneAddictionRef.current.classList.remove('unDisplayed');
    }

    function findPhone(){
        let addictionImg =  document.getElementById('phone')
        addictionImg.classList.remove('displayNone')
        if(!addictionFind.includes('phone')){
            setPopUpAddiction(true)
            const text = returnText('phoneText')
            setTextAddiction(text)
            setAddictionFind( addictionFind => [...addictionFind, 'phone'] )
            closeModal()
            toggle()
        }

    }

    return (
        <div className="cuisineContainer roomContainer" >
            <img className="arrowBack onClick" src={arrow} alt="" onClick={() => setRoom('salon')}  />
            <div className="frigoUp onClick " onClick={() => frigoUpRef.current.classList.remove('unDisplayed')}   ></div>
            <div className="frigoDown onClick" onClick={() =>  frigoDownRef.current.classList.remove('unDisplayed')} ></div>
            <img src={frigoDown} alt="" className="frigoDownImg unDisplayed onClick" onClick={() => frigoDownRef.current.classList.add('unDisplayed')} ref={frigoDownRef} />
            <img src={frigoUp} alt="" className="frigoUpImg unDisplayed onClick" onClick={() => frigoUpRef.current.classList.add('unDisplayed')}  ref={frigoUpRef} />

            <div src={phone} alt="" onClick={clickOnPhone} className="phoneCuisine onClick"></div>

            <div className="unDisplayed modal modalEventail" ref={modal} >
                <img src={redCross} alt="" className="closeModal" onClick={closeModal} width='20' />
                <img src={phoneOpen} alt="" ref={phoneOpenRef} className="imgModal unDisplayed"/>
                <div className="tempsEcranContainer unDisplayed onClick " ref={tempsEcranRef}  onClick={switchToAddiction}   ></div>
                <img src={phoneAddiction} alt="" ref={phoneAddictionRef} className="imgModal unDisplayed" onClick={findPhone} />
            </div>

            <div className="avoineContainer onClick " onClick={() => setAvoine(true)} ></div>
            <div className="haricotsContainer onClick " onClick={() => setHaricots(true)} ></div>
            <div className="lentilleContainer onClick" onClick={() => setLentille(true)} ></div>
            <div className="postItContainer onClick" onClick={() => setPostIt(true)} ></div>
            <div className="noixContainer onClick " onClick={() => setNoix(true)}  ></div>
            <div className="poisChicheContainer onClick " onClick={() => setPoisChiche(true)} ></div>
            <div className="prunneauxContainer onClick " onClick={() => setPrunneaux(true)} ></div>
            <div className="sonContainer onClick" onClick={() => setSon(true)} ></div>


            {isAvoine ? <PopUpImg img={avoine} set={setAvoine} />  :  null   } 
            {isHaricots ? <PopUpImg img={haricots} set={setHaricots} />  :  null   } 
            {isLentille ? <PopUpImg img={lentille} set={setLentille} />  :  null   } 
            {isNoix ? <PopUpImg img={noix} set={setNoix} />  :  null   } 
            {isPoisChiche ? <PopUpImg img={poisChiche} set={setPoisChiche} />  :  null   } 
            {isPostit ? <PopUpImg img={postIt} set={setPostIt} />  :  null   } 
            {isPrunneaux ? <PopUpImg img={prunneaux} set={setPrunneaux} />  :  null   } 
            {isSon ? <PopUpImg img={son} set={setSon} />  :  null   } 

            {isPopUpAddiction ? <PopUpAddiction src={phoneAddiction} text={textAddiction} set={setPopUpAddiction} toggle={toggle}/> : null}
        </div>
    )


}

export default Cuisine