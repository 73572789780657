import React from "react";
import './popUp.css'

const PopUpImg = ({img, set}) => {


    function unDisplayed(){
        set(false)
    }

    function unDisplayed(){
        set(false)
    }


    return(
        <div className="popUpContainer" onClick={unDisplayed}>
                <img src={img} alt="" />
        </div>
    )
}


export default PopUpImg