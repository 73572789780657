import React, {useEffect} from "react";
import Nav from "./nav/nav";

function App() {

  useEffect(() => {
    document.title = "Zon'Addict",
    document.head.innerHTML+=`
    <meta name="robots" content="noindex" data-react-helmet="true" />
    `
 }, []);


  return(
    <div>
      <Nav />
    </div>
  )
}

export default App;
