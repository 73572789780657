import React, { useEffect, useState } from "react";

import logoFree from '../assets/logoFree.jpg'
import addictionAlcool from '../assets/room/bureau/addictionAlccol.png'
import addictionMedicaments from '../assets/room/sdb/addictionMedicaments.png'
import addictionResine from '../assets/room/entree/resine.png'
import addictiionPhone from '../assets/room/cuisine/addictionPhone.png'
import addictionJeux from '../assets/room/salon/addictionJeux.png'
import addictionSexe from '../assets/room/chambre/addictionSexe.png'
import './endGame.css'

const SensiMessage = ({textSensi, setSensiMessage}) => {
    
    return (
        <div className="sensiMessageContainer">
            <div className="logoFreeContainer">
                <img src={logoFree} alt="logo free" width={350}/>
            </div>


            <div className="sensiContainer">
                <h1 className="mainTitle" >ADDICTIONS de la famille Zadikt</h1>

                <div className="sensiContent" >

                        <div className="imgContainer" >
                            <div><img src={addictionJeux} alt="ordinateur portable jeux" /></div>
                            <div>{textSensi.jeuxText.props.children[0]}<br/>{textSensi.jeuxText.props.children[1]}</div>
                        </div>

                        <div className="textSensi">
                            {textSensi.jeuxText.props.children[2]}
                            {textSensi.jeuxText.props.children[3]}
                            {textSensi.jeuxText.props.children[4]}
                        </div>
                    </div> 

                <hr />

                <div className="sensiContent" >
                    
                    <div className="imgContainer" >
                        <div className="imgContent" ><img src={addictionSexe} alt="préservatifs" /></div>
                        <div>{textSensi.sexeText.props.children[0]}<br/>{textSensi.sexeText.props.children[1]}</div>
                    </div>

                    <div className="textSensi">
                        {textSensi.sexeText.props.children[2]}
                        {textSensi.sexeText.props.children[3]}
                        {textSensi.sexeText.props.children[4]}

                    </div>
                </div> 
                <hr />
                
                <div className="sensiContent" >
                    
                    <div className="imgContainer" >
                        <div><img src={addictionMedicaments} alt="boite de médicaments" /></div>
                        <div>{textSensi.medicamentsText.props.children[0]}<br/>{textSensi.medicamentsText.props.children[1]}</div>
                    </div>

                    <div className="textSensi">
                        {textSensi.medicamentsText.props.children[2]}
                        {textSensi.medicamentsText.props.children[3]}
                        {textSensi.medicamentsText.props.children[4]}

                    </div>
                </div> 

                <hr />

                <div className="sensiContent" >
                    
                    <div className="imgContainer" >
                        <div><img src={addictionAlcool} alt="bouteille d'alcool" /></div>
                        <div>{textSensi.alcoolText.props.children[0]}<br/>{textSensi.alcoolText.props.children[1]}</div>
                    </div>

                    <div className="textSensi">
                        {textSensi.alcoolText.props.children[2]}
                        {textSensi.alcoolText.props.children[3]}
                        {textSensi.alcoolText.props.children[4]}

                    </div>
                </div> 

                <hr />
                
                <div className="sensiContent" >
                    
                    <div className="imgContainer" >
                        <div><img src={addictionResine} alt="résine de cannabis" /></div>
                        <div>{textSensi.resineText.props.children[0]}<br/>{textSensi.resineText.props.children[1]}</div>
                    </div>

                    <div className="textSensi">
                        {textSensi.resineText.props.children[2]}
                        {textSensi.resineText.props.children[3]}
                        {textSensi.resineText.props.children[4]}

                    </div>
                </div> 

                <hr />
                    
                <div className="sensiContent" >
                    
                    <div className="imgContainer" >
                        <div><img src={addictiionPhone} alt="téléphone portable" /></div>
                        <div>{textSensi.phoneText.props.children[0]}<br/>{textSensi.phoneText.props.children[1]}</div>
                    </div>

                    <div className="textSensi">
                        {textSensi.phoneText.props.children[2]}
                        {textSensi.phoneText.props.children[3]}
                        {textSensi.phoneText.props.children[4]}

                    </div>

                <br/>
                <hr />

                    <div className="playAgain onClick" onClick={() => setSensiMessage(false)} >Rejouer</div>
                </div> 
                <div className="sensiContent" >
                    <strong>Source :</strong>
                    {textSensi.phoneText.props.children[5]}
                    {textSensi.phoneText.props.children[6]}
                    {textSensi.resineText.props.children[5]}
                    {textSensi.resineText.props.children[6]}
                    {textSensi.alcoolText.props.children[5]}
                    {textSensi.alcoolText.props.children[6]}
                    {textSensi.jeuxText.props.children[5]}
                    {textSensi.jeuxText.props.children[6]}
                    {textSensi.sexeText.props.children[5]}
                    {textSensi.sexeText.props.children[6]}
                    {textSensi.medicamentsText.props.children[5]}
                    {textSensi.medicamentsText.props.children[6]}
                </div>
        </div>
        </div>
    )
}

export default SensiMessage