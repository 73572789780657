import React, { useState } from "react";
import { useRef } from "react";
import eventailImg from '../../assets/room/entree/eventailImg.png'
import eventailFlipedImg from '../../assets/room/entree/eventailFliped.png'
import manteauImg from '../../assets/room/entree/manteauFree.png'

import PopUpImg from "../../popUpImg/popUpImg";
import PopUpAddiction from "../../popUpAddiction/popUpAddiction";

import resine from '../../assets/room/entree/resine.png'

import jeuxText from "../../function/textAddiction";

import redCross from '../../assets/redCross.png'

import './entree.css'

const Entree = ({setRoom, setAddictionFind, addictionFind, toggle}) => {

    const eventailImgRef = useRef(null)
    const eventailFlipedImgRef = useRef(null)
    const modal = useRef(null)
    const resineContainer = useRef(null)
    const [isManteau ,setManteau] = useState(false)
    const [isPopUpAddiction, setPopUpAddiction] = useState(false)
    const [textAddiction, setTextAddiction] = useState()
    
    function openEventail(){ 
        modal.current.classList.remove('unDisplayed') 
        eventailImgRef.current.classList.remove('unDisplayed') ;
    }

    function flipedEventail(){ 
        eventailImgRef.current.classList.add('unDisplayed') ;
        eventailFlipedImgRef.current.classList.remove('unDisplayed');
        resineContainer.current.classList.remove('unDisplayed')
    }

    function findResine(){
        let addictionImg =  document.getElementById('resine')
        addictionImg.classList.remove('displayNone')
        if(!addictionFind.includes('resine')){
            setPopUpAddiction(true)
            const text = jeuxText('resineText')
            setTextAddiction(text)
            setAddictionFind( addictionFind => [...addictionFind, 'resine'] )
            closeModal()
            toggle()
        }    
    }

    function closeModal(){
        modal.current.classList.add('unDisplayed')
        eventailFlipedImgRef.current.classList.add('unDisplayed')
    }

    function isLoaded(e){
        console.log(e.target.width)
    }


    return (
        <div className="entreeContainer roomContainer" >
            <div className="doorEntreeToSalon onClick" onClick={() => setRoom('salon')} ></div>
            <div className="doorEntreeToPalier onClick" onClick={() => setRoom('palier')} ></div>

            <div className="eventail onClick" onClick={openEventail} ></div>
           
            <div className="unDisplayed modal modalEventail" ref={modal} >
   
                    <img src={redCross} alt="croix rouge" className="closeModal" onClick={closeModal} width='20' />
                    <img src={eventailImg} alt="évantail" ref={eventailImgRef} className="imgModal unDisplayed onClick" onClick={flipedEventail} />
                    <img src={eventailFlipedImg} alt="évantail retourné" ref={eventailFlipedImgRef} className="imgModal unDisplayed" />
                    <div className="resineContainer onClick unDisplayed" ref={resineContainer} onClick={findResine} ></div>
            </div>

            <div className="manteauContainer onClick " onClick={() => setManteau(true)} ></div>

            {isManteau ? <PopUpImg img={manteauImg} set={setManteau} />  :  <div></div>   } 
            {isPopUpAddiction ? <PopUpAddiction src={resine} text={textAddiction} set={setPopUpAddiction} toggle={toggle} /> : null}
        </div>
    )


}

export default Entree