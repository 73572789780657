import React from "react";
import { useState, useEffect, useRef } from "react";
import WhichRoom from "./whichRoom";
import './room.css'

import resine from '../assets/room/entree/resine.png'
import addictionJeux from '../assets/room/salon/addictionJeux.png'
import addictionSexe from '../assets/room/chambre/addictionSexe.png'
import addictionMedicaments from '../assets/room/sdb/addictionMedicaments.png'
import addcitonPhone from '../assets/room/cuisine/addictionPhone.png'
import addictionAlccol from '../assets/room/bureau/addictionAlccol.png'
import loupe from '../assets/loupe.png'
import Timer from "../function/timer";

import btnMaisonGrise from '../assets/btnMaisonGrise.png'
import btnMaisonRouge from '../assets/btnMaisonRouge.png'

const Game = ({setWinScreen, setLooseScreen}) => {

    const [room, setRoom] = useState('entree')
    const [isActive, setIsActive] = useState(true);
    const [addictionFind, setAddictionFind ] = useState([])
    const [enigmeFind, setEnigmeFind] = useState([])

    const sortieMaisonGrise = useRef(null)
    const sortieMaisonRouge = useRef(null)

    const addcitonArray = ['alcool', 'resine', 'medicaments', 'sexe', 'phone', 'jeux']

    const containsAll = addcitonArray.every(element => {
      return addictionFind.includes(element);
    });

    if (containsAll){
      sortieMaisonGrise.current.classList.add('unDisplayed')
      sortieMaisonRouge.current.classList.remove('unDisplayed')
    }

    function toggle(){
        setIsActive(!isActive)
    }

        return(
          <div className="darkBackGround" >
            <div className="gameContainer" id="gameContainer" >
                <Timer isActive={isActive} setLooseScreen={setLooseScreen}/>
                <WhichRoom toggle={toggle} room={room} setRoom={setRoom} setAddictionFind={setAddictionFind} addictionFind={addictionFind} enigmeFind={enigmeFind} setEnigmeFind={setEnigmeFind}  />
                <div className="sideBar">
                      <div className="loupeAddictionBarre" > 
                          <img src={loupe} alt="loupe" /> 
                          <div className="titleAddiction">Addictions <br />Trouvées</div>
                        </div>
                      <div className="addictionCannabis photoAddictionContainer ">
                        <img src={resine} alt="resine de cannabis" id="resine" className="photoAddictionSideBar displayNone" />
                      </div>
                      <div className="addictionSexe photoAddictionContainer " >
                       <img src={addictionSexe} alt="préservatif" id="sexe" className="photoAddictionSideBar displayNone" />
                      </div>
                      <div className="addicitonPhone photoAddictionContainer" >
                        <img src={addcitonPhone} alt="téléphone" id="phone" className="photoAddictionSideBar displayNone" />
                      </div>
                      <div className="addictionJeux photoAddictionContainer " > 
                        <img src={addictionJeux} id="jeux" alt="ordinateur portable" className="photoAddictionSideBar displayNone" />
                        </div>
                      <div className="addictionMedicaments photoAddictionContainer " >
                       <img src={addictionMedicaments} id='medicaments' alt="boite de médicaments" className="photoAddictionSideBar displayNone" />
                      </div>
                      <div className="addictionAlcool photoAddictionContainer" >
                       <img src={addictionAlccol} id='alcool' alt="bouteille d'alcool" className="photoAddictionSideBar displayNone" />
                      </div>
                      <div className="btnMaisonContainer" >
                        <img src={btnMaisonRouge} alt="boutton sortie de maison rouge" ref={sortieMaisonRouge} className="BtnMaisonRouge unDisplayed onClick   " onClick={() => setWinScreen(true)} />
                        <img src={btnMaisonGrise} alt="boutton sortie de maison grise" ref={sortieMaisonGrise} className="BtnMaisonGrise " />
                      </div>
            
                </div>
            </div>
            </div>
        )
    }

export default Game