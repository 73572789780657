import React from "react";
import winImg from '../assets/winImg.jpg'
import logoFree from '../assets/logoFree.jpg'
import WinScreenImg from '../assets/winScreen.jpg'
import './endGame.css'

const WinScreen = ({setSensiMessage, setWinScreen, setText}) => {


    function displaySensiMessage(){
        setText()
        setSensiMessage(true)
        setWinScreen(false)
    }

    return (

        <div className="winLooseScreenContainer">
            <div className="winLooseScreen">
                <img src={WinScreenImg} alt="écran de fin de jeux, Victoire" />
                <div className="btnMsgWinContainer" onClick={displaySensiMessage} ></div>
            </div>
        </div>

    )

}

export default WinScreen